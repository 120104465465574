
            //This is auto generated component. Do not change the generated file. If you need to add or change anything, please change or add in Portal.Sitemap
            //Generated on Tue Jul 02 2024 13:55:02 GMT-0400 (Eastern Daylight Time)

            import React from 'react';
            import Route from './SafeRoute';


			const Vehicle = React.lazy(() => import('../../pages/View/Vehicle'));
			const ViewAvailability = React.lazy(() => import('../../pages/View/ViewAvailability'));
			const ViewVehicleHistory = React.lazy(() => import('../../pages/View/ViewVehicleHistory'));
			const ViewVehicleHistoryExFull = React.lazy(() => import('../../pages/View/ViewVehicleHistoryExFull'));
			const ViewVehicleHistoryEx = React.lazy(() => import('../../pages/View/ViewVehicleHistoryEx'));
			const ViewRoutes = React.lazy(() => import('../../pages/View/ViewRoutes'));
			const ViewDailySchedule = React.lazy(() => import('../../pages/View/ViewDailySchedule'));
			const DriverArrivalStatus = React.lazy(() => import('../../pages/View/DriverArrivalStatus'));
			const RefusalList = React.lazy(() => import('../../pages/View/RefusalList'));
			const IncidentListing = React.lazy(() => import('../../pages/View/IncidentListing'));
			const EngineDiagnostics = React.lazy(() => import('../../pages/View/EngineDiagnostics'));
			const EngineDiagnosticReport = React.lazy(() => import('../../pages/View/EngineDiagnosticReport'));
			const Zones = React.lazy(() => import('../../pages/Maintain/Zones/Zones'));
			const ZoneTypes = React.lazy(() => import('../../pages/Maintain/Zones/ZoneTypes'));
			const DailyBlockEdit = React.lazy(() => import('../../pages/Maintain/DailySchedule/DailyBlockEdit'));
			const ReduxDetour = React.lazy(() => import('../../pages/Maintain/DailySchedule/ReduxDetour'));
			const Schedule = React.lazy(() => import('../../pages/Maintain/Scheduling/Schedule'));
			const Direction = React.lazy(() => import('../../pages/Maintain/Scheduling/Direction'));
			const Transfer = React.lazy(() => import('../../pages/Maintain/Scheduling/Transfer'));
			const TransferAudio = React.lazy(() => import('../../pages/Maintain/Scheduling/TransferAudio'));
			const ServiceList = React.lazy(() => import('../../pages/Maintain/Scheduling/ServiceList'));
			const ServiceDates = React.lazy(() => import('../../pages/Maintain/Scheduling/ServiceDates'));
			const TaggedDates = React.lazy(() => import('../../pages/Maintain/Scheduling/TaggedDates'));
			const StopEdit = React.lazy(() => import('../../pages/Maintain/Scheduling/StopEdit'));
			const InterStop = React.lazy(() => import('../../pages/Maintain/Scheduling/InterStop'));
			const MaintainRoute = React.lazy(() => import('../../pages/Maintain/Scheduling/MaintainRoute'));
			const HeadSignAssign = React.lazy(() => import('../../pages/Maintain/Scheduling/HeadSignAssign'));
			const Pattern = React.lazy(() => import('../../pages/Maintain/Scheduling/Pattern'));
			const MaintainTrips = React.lazy(() => import('../../pages/Maintain/Scheduling/MaintainTrips'));
			const Block = React.lazy(() => import('../../pages/Maintain/Scheduling/Block'));
			const Pieces = React.lazy(() => import('../../pages/Maintain/Scheduling/Pieces'));
			const MaintainVehicles = React.lazy(() => import('../../pages/Maintain/Vehicles/MaintainVehicles'));
			const VehicleCategory = React.lazy(() => import('../../pages/Maintain/Vehicles/VehicleCategory'));
			const ServiceStatus = React.lazy(() => import('../../pages/Maintain/Vehicles/ServiceStatus'));
			const Inspection = React.lazy(() => import('../../pages/Maintain/Inspection/Inspection'));
			const VehicleInspection = React.lazy(() => import('../../pages/Maintain/Inspection/VehicleInspection'));
			const WorkAssignments = React.lazy(() => import('../../pages/Maintain/DriverSchedule/WorkAssignments'));
			const HardwareRegistration = React.lazy(() => import('../../pages/Maintain/Hardware/HardwareRegistration'));
			const HardwareStatus = React.lazy(() => import('../../pages/Maintain/Hardware/HardwareStatus'));
			const HardwareSettings = React.lazy(() => import('../../pages/Maintain/Hardware/HardwareSettings'));
			const RMA = React.lazy(() => import('../../pages/Maintain/Hardware/RMA'));
			const WaysideSignStatus = React.lazy(() => import('../../pages/Maintain/Hardware/WaysideSignStatus'));
			const AssemblyStatus = React.lazy(() => import('../../pages/Maintain/Hardware/AssemblyStatus'));
			const MessageCategories = React.lazy(() => import('../../pages/Maintain/Messages/MessageCategories'));
			const Messages = React.lazy(() => import('../../pages/Maintain/Messages/Messages'));
			const IncidentFields = React.lazy(() => import('../../pages/Maintain/Incidents/IncidentFields'));
			const IncidentTypes = React.lazy(() => import('../../pages/Maintain/Incidents/IncidentTypes'));
			const IncidentEmailGroups = React.lazy(() => import('../../pages/Maintain/Incidents/IncidentEmailGroups'));
			const AdVideos = React.lazy(() => import('../../pages/Maintain/TOBI/AdVideos'));
			const AdVideoPlaylists = React.lazy(() => import('../../pages/Maintain/TOBI/AdVideoPlaylists'));
			const AdAssociations = React.lazy(() => import('../../pages/Maintain/TOBI/AdAssociations'));
			const AdVisualizer = React.lazy(() => import('../../pages/Maintain/TOBI/AdVisualizer'));
			const AdUserGroups = React.lazy(() => import('../../pages/Maintain/TOBI/AdUserGroups'));
			const PublishReports = React.lazy(() => import('../../pages/Maintain/Communities/PublishReports'));
			const GroupMapping = React.lazy(() => import('../../pages/Maintain/EngineDiagnostic/GroupMapping'));
			const CodeConfiguration = React.lazy(() => import('../../pages/Maintain/EngineDiagnostic/CodeConfiguration'));
			const AlertConfig = React.lazy(() => import('../../pages/Maintain/EngineDiagnostic/AlertConfig'));
			const StopBroadcast = React.lazy(() => import('../../pages/Maintain/StopBroadcast'));
			const CannedAnnouncements = React.lazy(() => import('../../pages/Maintain/CannedAnnouncements'));
			const BypassReason = React.lazy(() => import('../../pages/Maintain/BypassReason'));
			const LateCheckOutReason = React.lazy(() => import('../../pages/Maintain/LateCheckOutReason'));
			const MaintainEmergencyType = React.lazy(() => import('../../pages/Maintain/MaintainEmergencyType'));
			const HeadSign = React.lazy(() => import('../../pages/Maintain/HeadSign'));
			const DateTags = React.lazy(() => import('../../pages/Maintain/DateTags'));
			const AlertLog = React.lazy(() => import('../../pages/Maintain/AlertLog'));
			const About = React.lazy(() => import('../../pages/System/About'));
			const MaintainOperation = React.lazy(() => import('../../pages/System/MaintainOperation'));
			const Users = React.lazy(() => import('../../pages/System/Users'));
			const Communities = React.lazy(() => import('../../pages/System/Communities'));
			const Settings = React.lazy(() => import('../../pages/System/Settings'));
			const AuditLog = React.lazy(() => import('../../pages/System/AuditLog'));
			const HardwareAuditLog = React.lazy(() => import('../../pages/System/Diagnostics/HardwareAuditLog'));
			const HardwareStatusLog = React.lazy(() => import('../../pages/System/Diagnostics/HardwareStatusLog'));
			const VehicleDiagnostics = React.lazy(() => import('../../pages/System/Diagnostics/VehicleDiagnostics'));
			const StopVoicePreview = React.lazy(() => import('../../pages/System/StopVoicePreview'));
			const ATPExport = React.lazy(() => import('../../pages/System/ATPExport'));
			const ConfigStatus = React.lazy(() => import('../../pages/System/ConfigStatus'));
			const DailyRidershipSummary = React.lazy(() => import('../../pages/Reports/DailyRidershipSummary'));
			const PassengerCount = React.lazy(() => import('../../pages/Reports/PassengerCount'));  

            export default () => (
                        <React.Fragment>
							 <Route exact path="/" component={Vehicle} />
							 <Route path="/View/Vehicles" component={Vehicle} />
							 <Route path="/View/VehicleAvailability" component={ViewAvailability} />
							 <Route path="/View/History" component={ViewVehicleHistory} />
							 <Route path="/View/HistoryArea" component={ViewVehicleHistoryExFull} />
							 <Route path="/View/HistoryExclusiveArea" component={ViewVehicleHistoryEx} />
							 <Route path="/View/Routes" component={ViewRoutes} />
							 <Route path="/View/DailySchedule" component={ViewDailySchedule} />
							 <Route path="/View/DriverArrivalStatus" component={DriverArrivalStatus} />
							 <Route path="/View/RefusalList" component={RefusalList} />
							 <Route path="/View/Incidents" component={IncidentListing} />
							 <Route path="/View/EngineDiagnosticsCode" component={EngineDiagnostics} />
							 <Route path="/View/EngineMeasurementReport" component={EngineDiagnosticReport} />
							 <Route path="/Maintain/Zones/MaintainZones" component={Zones} />
							 <Route path="/Maintain/Zones/ZoneTypes" component={ZoneTypes} />
							 <Route path="/Maintain/DailySchedule/DailyBlock" component={DailyBlockEdit} />
							 <Route path="/Maintain/DailySchedule/Detours" component={ReduxDetour} />
							 <Route path="/Maintain/Scheduling/Schedules" component={Schedule} />
							 <Route path="/Maintain/Scheduling/Directions" component={Direction} />
							 <Route path="/Maintain/Scheduling/Transfer" component={Transfer} />
							 <Route path="/Maintain/Scheduling/TransferAudio" component={TransferAudio} />
							 <Route path="/Maintain/Scheduling/Services" component={ServiceList} />
							 <Route path="/Maintain/Scheduling/ServiceExceptions" component={ServiceDates} />
							 <Route path="/Maintain/Scheduling/TaggedDates" component={TaggedDates} />
							 <Route path="/Maintain/Scheduling/Stops" component={StopEdit} />
							 <Route path="/Maintain/Scheduling/StopPaths" component={InterStop} />
							 <Route path="/Maintain/Scheduling/Routes" component={MaintainRoute} />
							 <Route path="/Maintain/Scheduling/HeadSign" component={HeadSignAssign} />
							 <Route path="/Maintain/Scheduling/Patterns" component={Pattern} />
							 <Route path="/Maintain/Scheduling/Trips" component={MaintainTrips} />
							 <Route path="/Maintain/Scheduling/Blocks" component={Block} />
							 <Route path="/Maintain/Scheduling/Pieces" component={Pieces} />
							 <Route path="/Maintain/Vehicles/Vehicles" component={MaintainVehicles} />
							 <Route path="/Maintain/Vehicles/Categories" component={VehicleCategory} />
							 <Route path="/Maintain/Vehicles/Availability" component={ServiceStatus} />
							 <Route path="/Maintain/Inspection/InspectionDefects" component={Inspection} />
							 <Route path="/Maintain/Inspection/VehicleInspections" component={VehicleInspection} />
							 <Route path="/Maintain/DriverSchedule/WorkAssignments" component={WorkAssignments} />
							 <Route path="/Maintain/Hardware/Registration" component={HardwareRegistration} />
							 <Route path="/Maintain/Hardware/Status" component={HardwareStatus} />
							 <Route path="/Maintain/Hardware/Settings" component={HardwareSettings} />
							 <Route path="/Maintain/Hardware/ReturnsManagement" component={RMA} />
							 <Route path="/Maintain/Hardware/WaysideSignStatus" component={WaysideSignStatus} />
							 <Route path="/Maintain/Hardware/HardwareAssemblyStatus" component={AssemblyStatus} />
							 <Route path="/Maintain/Messages/Categories" component={MessageCategories} />
							 <Route path="/Maintain/Messages/Messages" component={Messages} />
							 <Route path="/Maintain/Incidents/IncidentFields" component={IncidentFields} />
							 <Route path="/Maintain/Incidents/IncidentTypes" component={IncidentTypes} />
							 <Route path="/Maintain/Incidents/EmailGroups" component={IncidentEmailGroups} />
							 <Route path="/Maintain/TOBI/Media" component={AdVideos} />
							 <Route path="/Maintain/TOBI/Playlists" component={AdVideoPlaylists} />
							 <Route path="/Maintain/TOBI/Assignments" component={AdAssociations} />
							 <Route path="/Maintain/TOBI/PlaylistVisualizer" component={AdVisualizer} />
							 <Route path="/Maintain/TOBI/UserGroups" component={AdUserGroups} />
							 <Route path="/Maintain/Communities/PublishReports" component={PublishReports} />
							 <Route path="/Maintain/EngineDiagnostic/VehicleGroupMapping" component={GroupMapping} />
							 <Route path="/Maintain/EngineDiagnostic/CodeConfiguration" component={CodeConfiguration} />
							 <Route path="/Maintain/EngineDiagnostic/AlertConfigration" component={AlertConfig} />
							 <Route path="/Maintain/Broadcasts" component={StopBroadcast} />
							 <Route path="/Maintain/ServiceAnnouncements" component={CannedAnnouncements} />
							 <Route path="/Maintain/MaintainBypassReasons" component={BypassReason} />
							 <Route path="/Maintain/LateCheck-OutReasons" component={LateCheckOutReason} />
							 <Route path="/Maintain/MaintainEmergencyTypes" component={MaintainEmergencyType} />
							 <Route path="/Maintain/HeadSign" component={HeadSign} />
							 <Route path="/Maintain/DateTags" component={DateTags} />
							 <Route path="/Maintain/AlertLogs" component={AlertLog} />
							 <Route path="/System/About" component={About} />
							 <Route path="/System/RoleOperations" component={MaintainOperation} />
							 <Route path="/System/Users" component={Users} />
							 <Route path="/System/Communities" component={Communities} />
							 <Route path="/System/SystemSettings" component={Settings} />
							 <Route path="/System/AuditLog" component={AuditLog} />
							 <Route path="/System/Diagnostics/HardwareAuditLog" component={HardwareAuditLog} />
							 <Route path="/System/Diagnostics/HardwareStatusLog" component={HardwareStatusLog} />
							 <Route path="/System/Diagnostics/VehicleDiagnostic" component={VehicleDiagnostics} />
							 <Route path="/System/StopVoicePreview" component={StopVoicePreview} />
							 <Route path="/System/ATPExport" component={ATPExport} />
							 <Route path="/System/ConfigStatus" component={ConfigStatus} />
							 <Route path="/Reports/DailyRidershipSummary" component={DailyRidershipSummary} />
							 <Route path="/Reports/RawPassengerCount" component={PassengerCount} />   
                        
                        </React.Fragment>
                    );
        